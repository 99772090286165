header {
    display: flex;
    position: fixed;
    top: 16px;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 999;
}

.sb-header__site-logo {
    height: 64px;
    object-fit: contain;
}